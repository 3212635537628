<template>
  <div class="page-container">
    <el-row>
      <div id="scoreTrendChart" ref="scoreTrendChart"></div>
    </el-row>

    <el-row class="page-left" style="margin-top: 20px">
      <el-form :inline="true" :model="form" label-width="40px">
        <el-form-item label="科目">
          <el-select
            v-model="form.subject"
            placeholder="请选择科目"
            style="width: 120px"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row class="page-left-dynamic">
      <div>
        <el-form
          :model="dynamicValidateForm"
          ref="dynamicValidateForm"
          label-width="100px"
          class="demo-dynamic"
        >
          <!-- :label="'域名' + index" -->
          <el-form-item
            v-for="(domain, index) in dynamicValidateForm.domains"
            :key="domain.key"
            :prop="'domains.' + index + '.value'"
            :rules="{
              required: true,
              message: '内容不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="domain.value"
              style="width: 300px"
              @keyup.enter.native="addDomain"
            ></el-input>
            <el-button
              v-throttle
              type="danger"
              size="medium"
              icon="el-icon-minus"
              @click.prevent="removeDomain(domain)"
              style="margin-left: 20px"
            ></el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-throttle
              type="primary"
              size="medium"
              icon="el-icon-plus"
              @click="addDomain"
            ></el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-row>

    <el-row>
      <div id="remarks-tip">
        <span>
          点击“添加”符号弹出此“学校选择穿梭框”，载入信息时检测“考试”字段是否为空值，若
          <br />是，加载全部学校；若否，则只加载所选考试均有参与的学校
          <br />已选列表中的学校顺序按照添加顺序即可
        </span>
      </div>
    </el-row>

    <el-row>
      <div id="page-transfer">
        <div id="page-transfer-title">
          <p>选择考试</p>
        </div>
        <el-transfer
          v-model="value"
          :data="data"
          :titles="['待选列表', '已选列表']"
        ></el-transfer>
        <div id="page-transfe-btn">
          <el-button v-throttle type="primary" icon size="medium"
            >保存</el-button
          >
          <el-button v-throttle type="primary" icon size="medium"
            >取消</el-button
          >
        </div>
      </div>
    </el-row>

    <el-row>
      <div id="remarks-tip">
        <span>
          点击“添加”符号弹出此“考试选择穿梭框”，载入信息时检测“学校”字段是否为空值，若
          <br />是，加载全部考试完成状
          态的考试；若否，则只加载所选学校均有参与的考试完成状态的考试
          <br />已选列表中的考试将按照考试日期，时间排列，而非按照添加顺序
        </span>
      </div>
    </el-row>

    <el-row>
      <div id="page-transfer">
        <div id="page-transfer-title">
          <p>选择学校</p>
        </div>
        <el-transfer
          v-model="value"
          :data="data"
          :titles="['待选列表', '已选列表']"
        ></el-transfer>
        <div id="page-transfe-btn">
          <el-button v-throttle type="primary" icon size="medium"
            >保存</el-button
          >
          <el-button v-throttle type="primary" icon size="medium"
            >取消</el-button
          >
        </div>
      </div>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'examAnalyze',
  data() {
    const generateData = (_) => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          key: i,
          label: `备选项 ${i}`,
          disabled: i % 4 === 0,
        })
      }
      return data
    }
    return {
      data: generateData(),
      value: [1, 4],
      form: {
        subject: '',
      },
      dynamicValidateForm: {
        domains: [
          {
            value: '',
          },
        ],
        email: '',
      },
      charts: {
        scoreTrendChart: '',
      },
    }
  },
  mounted() {
    this.init()
    //调整窗口大小重新渲染图表
    window.onresize = () => {
      this.reloadChart()
    }
  },
  methods: {
    init() {
      this.initChart()
      this.reloadChart()
    },
    initChart() {
      var scoreTrendChart = this.$echarts.init(this.$refs.scoreTrendChart)
      this.charts.scoreTrendChart = scoreTrendChart
      var scoreTrendOption = {
        title: {
          text: '成绩走势图',
          // subtext: '成绩走势图',
          x: 15,
          // subtextStyle: {
          //   fontSize: 16
          // },
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          show: false,
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: 'Video Ads',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: 'Direct',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320],
          },
          {
            name: 'Search Engine',
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
        ],
      }
      scoreTrendChart.setOption(scoreTrendOption)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now(),
      })
    },
    reloadChart() {
      this.charts.scoreTrendChart.resize()
    },
  },
}
</script>
<style>
#scoreTrendChart {
  width: 40%;
  height: 400px;
  margin: 0 auto;
  border: 1px solid;
  padding: 10px 0;
}

.page-left {
  margin-left: 30%;
}

.page-left-dynamic {
  margin-left: 24%;
}

#page-transfer {
  margin: 0 auto;
  width: -webkit-fit-content;
  border: 1px solid;
  padding: 50px 50px 20px 50px;
}

#page-transfer-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

#page-transfe-btn {
  margin-top: 30px;
  text-align: center;
}

#remarks-tip {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
}
</style>
